.card {
  padding-right: 15px;
  padding-left: 15px;
  border-image-repeat: stretch !important;
  animation-duration: 2s;
}

.card .title img {
  width: 32px;
  height: 32px;
  image-rendering: pixelated;
  margin-left: 5px;
}

.card .title button {
  padding: 0;
  border: none;
  background: none;
}
.card .title button:focus {
  outline: none;
}

.presentation {
  display: flex;
  flex-direction: column;
}

#ciandt a {
  font-size: 15pt;
  text-decoration: none;
}

#ciandt a .red {
  color: #ed1941;
  text-decoration: none;
}

#ciandt a .blue {
  color: #20bec6;
  text-decoration: none;
}

.checkMeOut {
  margin-top: 20px;
}

.dismiss-btn {
  position: absolute;
  right: 5px;
  top: 6px;
}

.social {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.social i {
  margin-left: 15px;
}

/* Smaller desktops */
@media screen and (max-width: 1300px) {
  .card-container {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
}

/* Tablets and phones */
@media screen and (min-width: 801px) {
  .card {
    flex-basis: 50%;
    align-self: center;
    z-index: 50;
  }
}

@media screen and (max-width: 800px) {
  .card {
    order: 1;
  }

  .dismiss-btn {
    display: none;
  }
}
